import React from 'react'
import '../../pages/style.css';
import { Container, Row, Col } from 'react-bootstrap'
import Text from '../Homepage/Text'

import profile from "../../data/profile"
function Home() {
  return (
    <div className='homepagebackground'>
      <Container>
        <Row>
          <Col md={7}>
            <h2 className='headtext'>Hello <span className='wave'>👋 </span></h2>
            <h2 className='nametext'>I'm {profile.name} from "ON K0L 2W0, Canada"</h2>
            <h3 className=''> {profile.summary}</h3>
            <span></span>
            <Text />
            {/* {
              profile.social_list.map((item, index)=>(
                <button key={index} onClick={() => {
                              window.open(item.link);
                            }}
                        className='socailmediabtn'>{item.title}
                </button>
              ))
            } */}
            
            <ul className='skilllist'>
                            <Row>
                                <h3>Skills</h3>
                                <Col md={12}>
                                    {
                                        profile.skills.map((item, index)=>(
                                            <li key={index}>
                                                {item.title}
                                            </li>
                                        ))
                                    }
                                    
                                </Col>
                            </Row>
                        </ul>
          </Col>

          <Col md={5}>
            <div className="imagedeveloper">
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home