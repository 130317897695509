import React from 'react'
import '../../pages/style.css';
import Typewriter from "typewriter-effect";
import profile from "../../data/profile"
function Text() {
  return (
    <Typewriter
      options={{
        strings: profile.titles,
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  )
}

export default Text