const profile = {
  name: "Sean Davoli",
  titles: [
    "Javascript Fullstack Developer",
    "Frontend Developer",
    "Backend Developer",
  ],
  resume:"https://drive.google.com/file/d/1-zYC6JhWpnYo2NPpsuW4PLjcFgbXCykV/view?usp=drivesdk",
  summary:
    "I am a Senior Software Engineer who has 9 years of experience. I'd like to spend my spare time on my personal project and getting trends of the latest technology to get hands-on experience and make easy my life with software technology. If you are interested in me, You can get more detailed information about me on LinkedIn. ",
  social_list: [
    {
      title: "LinkedIn",
      link: "https://www.linkedin.com/in/sean-d-594a1a223/",
    },
  ],
  about_me: `
  Hello, my name is Sean and I have over 9 years of experience working as a web developer. During this time, I have honed my skills in software engineering and have gained a deep understanding of many different codebases and technologies. I am passionate about using my expertise to help businesses succeed in their online endeavors.
<br/><br/>
In my current role as a Full Stack Engineer at yocoin.org, I am responsible for developing core features for the gambling platform, writing well-designed and testable code, and integrating software components into a fully functional software system. I also contribute to the Hadoop cluster Kettle features, provide maintenance to different code lines of the Pentaho tools, and troubleshoot and debug existing systems.
<br/><br/>
Previously, I worked as a Frontend Engineer at Aurick Construction Group where I developed a live streaming feature, integrated payment systems such as PayPal and WeChat, and implemented shipping track features using Aftership service and PostEasy. I also developed multi-language features for react components and managed work board for task tickets for other developers.
<br/><br/>
In my earlier roles as a Web Developer at Broker link Software Inc and a Full Stack Developer on Upwork, I worked on a variety of projects using technologies such as Laravel, CakePHP, and React.js. I am also proficient in HTML, CSS, JavaScript, and have experience with Node.js, PHP, GraphQL, Vue.js, SQL, and Git.
<br/><br/>
Overall, I am a dedicated and skilled developer who is well-versed in a wide range of technologies and always strives to deliver high-quality code. I am excited about the opportunity to leverage my experience to help your business succeed.
  `,
  skills: [
    {
      title: "Agile Methodologies",
      start_year: "2012",
    },
    {
      title: "HTML & CSS & Javascript & BootStrap",
      start_year: "2012",
    },
    {
      title: "Typescript",
      start_year: "2014",
    },
    {
      title: "PHP & Laravel & CI",
      start_year: "2015",
    },
    {
      title: "Node & Express & Nest & Fast",
      start_year: "2013",
    },
    {
      title: "Graphql & WebSocket & Socket.io",
      start_year: "2013",
    },
    {
      title: "React.js & Redux & Next.js & BootstrapReact & MaterialReact",
      start_year: "2015",
    },
    {
      title: "Vue.js & Vuex & Vuetify & Nuxt.js & BootstrapVue & ElementVue",
      start_year: "2015",
    },
    {
      title: "AWS & AWS Lambda & AWS RDS & API gateway & Route53 & AWS CLI ...",
      start_year: "2015",
    },
    {
      title: "GIT & GitHub & BitBucket & CD/CI",
      start_year: "2015",
    },
    {
      title: "Apache2 & Nginx & DNS & SSL & Virtual Host & Linux",
      start_year: "2015",
    },
  ],
  projects: [
    {
      name: "YOCOIN-Try247",
      imgs: [
        {
          src: "https://user-images.githubusercontent.com/47503803/233245683-ccc22421-1a36-4c25-9595-a150f82bc66a.png",
          des: "landing page",
        },
        {
          src: "https://user-images.githubusercontent.com/47503803/233245705-c91e7280-d10b-4c90-9683-4c16422e3e43.png",
          des: "Card game list",
        },
        {
          src: "https://user-images.githubusercontent.com/47503803/233245715-2d3dfd08-a73f-48da-9dbd-df487c9e8ba6.png",
          des: "Card game",
        },
      ],
      presentation: {
        summary: `Try247 is India's first and most trusted betting exchange, which offers sportsbooks, live casinos, and live Indian card games. Unlike most betting sites, on Try247, users bet against other players rather than the house, which ensures transparency and increases a player's chances of winning. `,
        roles: [
          "Developed the backend and frontend of the betting platform, including features such as exchange betting and live casino",
          "Documented the backend API using Swagger to reduce development time",
          "Collaborated with the design team to incorporate desktop, tablet, and mobile responsive designs",
          "Analyzed company product value and several attribute graph and estimated the product sale",
          "Writing well-designed, testable code",
          "Integrating software components into a fully functional software system",
          "Deploying programs and evaluating user feedback",
          "Documenting and maintaining software functionality",
          "Supporting different tool installers",
          "Troubleshooting, debugging and upgrading existing systems",
          "Implementing CD/CI for auto-deployment and Configuring out AWS EC2",
        ],
        s: ``,
        t: ``,
        a: ``,
        r: ``,
      },
    },
    {
      name: "Aruick-connexionlivestock",
      imgs: [
        {
          src: "https://user-images.githubusercontent.com/47503803/233246506-d956958c-f35f-400a-90c8-6de7adf0064e.png",
          des: "landing page",
        },
        {
          src: "https://user-images.githubusercontent.com/47503803/233246667-904bbaf2-2619-4281-a9f3-be18ffd8a685.png",
          des: "Card game list",
        },
        {
          src: "https://user-images.githubusercontent.com/47503803/233246778-85bf9ee1-492c-4cf3-8b48-e15b4ed16e78.png",
          des: "Card game",
        },
      ],
      presentation: {
        summary: `Connexion Livestock is an online marketplace for buying and selling livestock, with features like online auctions and real-time bidding. It also offers livestock transportation and resources for managing livestock operations, such as market insights and animal health information. Customer support is provided for a seamless experience.`,
        roles: [
          "Leading the development team in designing and implementing the backend and frontend of the betting platform, as well as the features such as exchange betting and live casino.",
          "Collaborating with the design team to ensure the platform is desktop, tablet, and mobile responsive.",
          "Documenting the backend API using Swagger to reduce development time.",
          "Analyzing the company product value and several attribute graphs to estimate the product sales.",
          "Writing clean, testable, and efficient code.",
          "Integrating software components into a fully functional software system.",
          "Deploying programs and evaluating user feedback.",
          "Documenting and maintaining software functionality.",
          "Troubleshooting, debugging and upgrading existing systems.",
          "Implementing CD/CI for auto-deployment and configuring out AWS EC2.",
        ],
        s: ``,
        t: ``,
        a: ``,
        r: ``,
      },
    },
    {
      name: "Softbroke Gaming",
      imgs: [
        {
          src: "https://user-images.githubusercontent.com/47503803/233249494-861f44ea-412c-493a-b25f-c23594ebbe31.png",
          des: "",
        },
        {
            src: "https://user-images.githubusercontent.com/47503803/233249559-6efbb29a-80a6-4616-8ac8-e1ab99963b0b.png",
            des: "",
          },
      ],
      presentation: {
        summary: `Softbroke Gaming is a software company that provides customizable solutions for the online gaming industry. They offer products such as online casinos, sportsbook software, and lottery systems with features like real-time reporting and player management. They also offer technical support and consulting services.`,
        roles: [
          "Developing and maintaining software for online gaming platforms, such as sports betting and casino games.",
          "Collaborating with designers and other developers to create user-friendly and visually appealing interfaces.",
          "Writing clean, efficient, and maintainable code using programming languages such as JavaScript, HTML, and CSS.",
          "Debugging and troubleshooting software issues, and working with the QA team to ensure software quality and performance.",
          "Integrating different software components to create a fully functional software system.",
          "Documenting software functionality and maintaining code repositories.",
          "Keeping up-to-date with the latest software development technologies and industry trends.",
          "Participating in code reviews and providing constructive feedback to other developers.",
          "Communicating with project managers and stakeholders to understand project requirements and deadlines.",
        ],
        s: ``,
        t: ``,
        a: ``,
        r: ``,
      },
    },
    {
      name: "",
      imgs: [
        {
          src: "",
          des: "",
        },
      ],
      presentation: {
        summary: ``,
        s: ``,
        t: ``,
        a: ``,
        r: ``,
      },
    },
  ],
};
export default profile